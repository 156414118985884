import Helmet from "react-helmet"
import React from "react"
import Typsaf from "src/components/modules/typsaf"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"
import FooterlinksSingle from "@tightrope/footerlinks/footerlinks_single.js"



export default function ThankYouAmo() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
       .footerlinkssingle-module--br {
         position: absolute;
         bottom: 10px;
         left: 50%;
         transform: translateX(-50%);
       }

    }
    `}
    </style>
    <title>Thank you for downloading Freshy Search</title>
    </Helmet>

    <section>
      <Typsaf data={Data} siteData={SiteData}></Typsaf>
      <FooterlinksSingle></FooterlinksSingle>
    </section>
    </>
  )
}
