import React from "react"
import JSONData from "src/../branding/site-metadata.json"
import Styles from "./css/typsaf.module.scss"
import {isChrome,isFirefox} from "../lpscripts/browserdetect"



class Typsaf extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      display: 'block',
      pageData: props.data,
      data: JSONData
    }
  }
  componentDidMount() {
    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
  }

  render() {
    return (
      <>
    <div className={Styles.safContainer}>

      <div className={Styles.grid}>

        <div className = {Styles.gridItem}>
          <h1>Search the Web with Freshy Search</h1>
          <h3>Search Freshy Recipes, Manuals, TV/Movies, Templates and the Web</h3>
          <div className={Styles.main}>
            <img className={Styles.steps} src="/assets/serpt1.png" />
            <img className={Styles.steps} src="/assets/serpt2.png" />
            <img className={Styles.steps} src="/assets/serpt3.png" />
          </div>
          <div className={Styles.submain}>
            <div className={Styles.step}><p><strong>Step 1:</strong></p><p>Click icon to see Freshy Search verticals & Search Engine shortcuts</p></div>
            <div className={Styles.step}><p><strong>Step 2:</strong></p><p>Type shortcut and your search</p></div>
            <div className={Styles.step}><p><strong>Step 3:</strong></p><p>Get instant results from Freshy Search</p></div>
          </div>
        </div>

      </div>
    </div>
</>

    )
  }
}

export default Typsaf
